.imageContent {
    margin: 15px;
    /*border-radius: 20px;*/
    overflow: hidden;
    background-color: #FFF;
}

.blockImage {
    border-radius: 10px;
}

.break {
    padding-top: 10px;
}

.imageContent :global .splide__pagination {
    display: flex !important;
}

.imageContent :global .splide__pagination__page {
    background: #bebebe !important;
}

.imageContent :global .splide__pagination__page.is-active {
    background: #5b5b5b !important;
}
