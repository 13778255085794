.app-container {
    max-width: 540px;
    margin: 0 auto;
}
.slot {
    position: relative;
    display: inline-block;
    height: 80px;
    width: 60px;
  }
  
section {
    position: absolute;
    border-radius: 15px !important;
    /* border: 3px solid black !important; */
    width: 60px;
    height: 60px;
    overflow: hidden;
    /* background-color: grey; */
    border-radius: 2px;
    /* border: 1px solid lightgrey; */
    color: white;
    font-family: sans-serif;
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    cursor: default;
  }
  
.container {
    position: absolute;
    top: 2px;
    width: 100%;
    transition: top ease-in-out 0.5s;
    text-align: center;
  }
.roll {
    width: 215px;
    cursor: pointer;
    background-color: yellow;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    border-radius: 20px;
    border: 3px solid black;
  }
  
.rolling {
    animation: blinkingText 1.2s infinite;
  }
.term-container {
    height: calc(100vh - 300px);
    overflow-y: scroll;
    font-size: 14px;
    font-family: Sarabun;
    color: #000000;
}

.ionic-alert {
    text-align: center;
}

.alert-head .sc-ion-alert-md {
    text-align: center;
}

.block-center {
    justify-content: center !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    display: flex !important;
}

.pin-pwd {
    padding: 0px !important;
    display: flex;
    justify-content: space-between;
    margin: 0px;
    background: none;
    max-width: 200px;
    width: 50%;
}

.pin-pwd > .pin-full {
    background-color: #0c0c0c;
    border: 2px solid #0c0c0c;
}

.pin-pwd > * {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    display: inline-block;
}

.pin-pwd > .pin-hollow {
    background-color: white;
    border: 2px solid #0c0c0c;
}

.ion-img {
    margin: 15px;
    padding-left: 2px;
    padding-right: 2px;
}

.alertPromptAdd {
    color: green;
}

.alertPromptCancel {
    color: red;
}

.kgo-btn {
    --background: var(--kgo-color);
    border-radius: 8px;
}

.kgo-btn-radius {
    --background: var(--kgo-color);
    --border-radius: 50%;
}

.kgo-btn.button-disabled {
    --background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.kgo-btn-dark {
    --background: rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.kgo-btn-light {
    --background: #F8F8F8;
    margin: 0;
    color: #242424;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.11);
}

.kgo-btn-scan {
    z-index: 1;
    right: 85%;
    top: -40%;
    bottom: 0;

    position: absolute;
    --background: #FFFFFF;
    margin: 0;
    color: #242424;
    border: 4px solid #797979;
    box-sizing: border-box;
    /* Drop Shadow */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 50%;
    --border-radius: 50%;
    height: 60px
}

.kgo-btn-browse {
    z-index: 1;
    right: 0;
    bottom: -15px;
    position: absolute;
    --background: #FFFFFF;
    margin: 0;
    color: #242424;
    border: 4px solid #797979;
    box-sizing: border-box;
    /* Drop Shadow */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 50%;
    --border-radius: 50%;
    height: 60px
}

.kgo-btn-browse.kgo-color {
    --background: var(--kgo-color);
    border: 4px solid #FFFFFF;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25))
}

@media screen and (max-width: 480px) {
    .kgo-camera video {
        width: 280px;
    }
}

.kgo-input-light {
    --background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    /* Drop Shadow */

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
}

.kgo-input-light.form {
    border: 1px solid #C1C1C1;
}

.kgo-bar {
    --background: var(--kgo-color);
    height: 58px;
    color: #ffffff;
}

.kgo-header {
    --background: var(--kgo-color);
}

.kgo-btn-store {
    --background: #242424;
    border-radius: 8px;
}

.kgo-bar-store {
    --background: #242424;
    height: 58px;
    color: #ffffff;
}

.kgo-bar ion-title, ion-buttons {
    height: 58px;
    font-size: 16px;
}

.kgo-card {
    --background: #E2E2E2;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 15px 0 0 0;
}

.kgo-light {
    --background: #FFFFFF;
}


.card-link {
    min-height: 160px;
    padding: 4px;
    margin: 0 4px;
}

.card-link img {
    height: 90px;
}


.kgo-card ion-card-content {
    padding-top: 9px;
    padding-bottom: 9px;
}

.kgo-card2 {
    --background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-bottom: 0;
}

.kgo-card2 ion-card-content {
    padding-top: 0;
    padding-bottom: 0;
}

.kgo-card-color, .kgo-card-img {
    position: relative;
    --background: var(--kgo-color);
}

.kgo-card-color-disabled {
    position: relative;
    opacity: 0.5;
    --background: #FFFFFF;
}

.kgo-card-img:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('./kgo-shadow.png') no-repeat center center fixed;
    opacity: 13%;
}

.kgo-row {
    padding-left: 16px;
    padding-right: 16px;
}

.kgo-row ion-col {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.kgo-card-tran {
    position: relative;
    height: calc(100vh - 370px);
    overflow-y: scroll;
}

.kgo-card-tran-store {
    position: relative;
    height: calc(100vh - 330px);
    overflow-y: scroll;
}

.kgo-card-tran-page {
    position: relative;
    --background: #FD9E18;
    height: calc(100vh - 80px);
    overflow-y: scroll;
}

.kgo-row-tran {
    background: #FFFFFF;
    border-radius: 12px;
    margin: 12px 0;
}

.kgo-icon-tran {
    font-size: 28px;
    margin-top: 10px;
}

.kgo-text-tran {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

.kgo-tran-detail {
    padding: 10px 16px 0 16px;
}

.kgo-send-detail {
    padding: 20px 16px;
}

.kgo-m {
    margin-top: 15px;
}

@media screen and (max-height: 700px) {
    .kgo-m {
        margin-top: 0;
    }
}

.kgo-row-bottom {
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    .kgo-row-bottom {
        position: absolute;
        width: 100%;
        bottom: 77px;
    }
}

/*TODO not work find modal-wrapper*/
.small-modal .modal-wrapper {
    position: absolute;
    bottom: 0;
    min-height: initial;
    top: initial;
    height: 50%;
}

.small-modal .sc-ion-modal-ios-h:first-of-type {
    /*--backdrop-opacity: var(--ion-backdrop-opacity, 0.4);*/
    height: 50%
}

.kgo-btn-radius {
    --border-radius: 22px;
    font-size: 12px;
    font-weight: 600;
}

.kgo-btn-profile-image {
    z-index: 1;
    right: 35%;
    bottom: -7%;
    position: absolute;
    margin: 0;
    box-sizing: border-box;
    border-radius: 50%;
    --border-radius: 50%;
    /*height: 30px;*/

    border: 4px solid #FFFFFF;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}

.ion-text-left {
    margin-right: auto;
}

.mission-text {
    /*margin-top: 16px;*/
    font-size: 22px;
    color: #242424;
}

.mission-card-content {
    color: #FFFFFF;
    /*height: 95px;*/
}

.stepper.RFS-StepperContainer {
    padding: 24px 0;
}

.stepper .RFS-StepContainer {
    padding: 0;
}

.label-form {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    color: #242424;
}

.receive-color {
    background: #F85555;
}

.text-receive-color{
    color: #F85555;
}

.send-color{
    background: #45B865;
}

.text-send-color{
    color: #45B865;
}

.voucher-list {
    height: calc(100vh - 440px);
    overflow-y: scroll;
}


.filterTran{
    text-align: center !important;
}

.filterTran select,input{
    background: #FFFFFF;
    color: #0c0c0c;
}

.filterTran input{
    margin-right: 5px;
}

.filterTranText{
    display: none;
    color: #0c0c0c;
}

.card-promotion{
    font-size: 16px;
    font-weight: bold;
    margin: 20px;
    color: #242424;
}

.card-promotion-list{
    font-size: 16px;
    font-weight: bold;
    color: #242424;
    padding-right: 3px;
}

.biz-detail{
    font-size: 18px;
}

@media screen and (max-width: 350px) {
    .filterTran{
        text-align: left;
    }
    .filterTran select{
        width: 100%;
    }
    .filterTranText{
        text-align: left;
        display: block;
        margin-left: 5px;
    }
    .filterTranDad{
        display: none;
    }

    .card-promotion{
        font-size: 14px;
        margin: 20px 0px;
    }

    .card-promotion-list{
        font-size: 14px;
        margin: 10px 0;
    }

    .card-promotion-list .address{
        display: none;
    }

    .filterTran input{
        margin-right: 0px;
        margin-bottom: 5px;
        width: 100%;
    }

    .biz-detail{
        font-size: 14px;
    }
}

.voucher-list{
    height: calc(100vh - 440px);
    overflow-y: scroll;
}

.recomment-text {
    color: #ffff;
    display:flex;
    margin-right:12px;
    justify-content: center;
    background-color:#FD9E18;
    padding:5px 5px 5px 5px;
    border-radius: 0px 0px 5px 5px;
    font-size: 12px;
}

@keyframes hilightAnim {
    0% {color:#ff8100;}
    50%   {color: gold;}
    100% {color:#ff8100;}
}

@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery {
    background-color: white;
    padding: 4px;
    border-radius: 13px;
}
.image-gallery-image {
    height: 240px;
    border-radius: 12px 12px 0 0;
    object-fit: cover;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 80px;
    object-fit: cover;
}


.coin-dollar {
    width: 20px;
    vertical-align : sub;
    margin-left:7px;
    margin-right:7px;
}

.cover-upload-image {
    background:#fff;
    border-radius: 12px;
}


.bg-secondary {
    --background: #F5F4F4;
}

hr {
    border-bottom: 1px solid #F4F4F4;
    margin: 10px 0;
    width: 100%;
}

.card-shadow:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 12px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.65) 90%);
}

.card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.card-header-b {
    color: #ffffff;
    position: absolute;
    z-index: 2;
}


.kgo-badge {
    display: inline-block;
    border-radius: 12px;
    padding: 3px 8px 3px 8px;
    margin: 5px 3px 0 3px;
    font-size: 10px;
}
.select-voucher {
    width: 50%;
    display:inline-block;
    padding:12px;
    color: #000000;
    font-weight: 400;
}

.active-voucher {
    color:#9D9D9D;
    border-bottom:3px solid #F99746;
}

.div-recommend {
    width: 80px;
    height: 110px;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    margin-right: 4px;
    flex-shrink: 0;
    filter: drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.16));
}

.block-recommend {
    color: black;
    height: 30px;
    background-color:#fff;
    font-weight:500;
    font-size: 10px;
    text-align:center;
    margin-top:80px;
    padding-top:5px;
    padding-bottom:5px;
    border-radius: 0px 0px 8px 8px;
    align-items:center;
    justify-content:center;
    display:flex;
}


.selected-filter-type {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.selected-filter-type > div > h4 {
    color: #FF6E07;
}

.mission-success{
    /*margin-right: 5%;*/
    /*margin-top: 5%;*/
    background-color: #3CB44C;
    border-radius: 10px;
    color: #FFFFFF;
    position:absolute;
    right:0;
    font-size:14px;
    padding: 4px 4px 4px 4px;
    font-weight:1000;
    z-index:2;
    height:21px;
    width:21px;
    display: flex;
    align-items: center;
    justify-content: center;
}
