.container {
    display: flex;
    margin: 10px 15px;
    margin-top: 20px;
    margin-bottom: 30px;
    align-items: center;
    position: sticky;
    top: 20px;
    z-index: 10;
}

.container :global .rmdp-container {
    flex: 1;
    padding-right: 15px;
}

.icon {
    font-size: 24px;
    padding-right: 10px;
    color: #737373
}

.closeIcon {
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 12px;
}

.searchIcon {
    font-size: 30px;
    color: #FFF;
}

.searchButton {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #EF7732 0%, #F29C3E 100%);
    border: 1px solid #EBEBEB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 9px 15px;
    transition: all 0.3s
}

.searchButton:active {
    opacity: 0.8
}

.contentSearch {
    position: relative;
    font-size: 18px;
    display: flex;
    align-items: center;
    background-color: #FFF;
    border: 1px solid #e3e3e3;
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    width: 100%;
}
