.rayong-card {
  margin-top: 10px;
}

.rayong-card-first {
  margin-top: 20px;
}

.status-mission-success {
  background-color: #cdcccc;
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  padding: 4px 10px 4px 10px;
  align-items: end;
}

.status-mission {
  background-color: #ffa412;
  border-radius: 6px;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  padding: 4px 10px 4px 10px;
}

.mission-name {
  font-size: 14px;
  color: #c28017;
}

.mission-value {
  font-size: 14px;
  color: #808080;
  display: flex;
  padding-top: 3px;
}

.mission-value-image {
  width: 35px;
  margin-right: 5px;
}

.mission-detail {
  font-size: 16px;
}

.mission-image {
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.5;
}

.mission-image image {
  width: 100%;
  object-fit: cover;
}
