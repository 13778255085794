.container {
    display: flex;
    flex-direction: column;
    background-color: #FFF
}

.selectContent {
    flex: 1;
    position: relative;
}

.contentGallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    position: relative;
}

.topicHeader {
    position: relative;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.highlight {
    color: #4E8139;
}

.boxRandom {
    margin-top: 0;
    max-width: 150px;
    padding: 0 20px;
}

.countRandomBoxContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.countRandomBox {
    margin-top:60px;
    text-align: center;
    background-color: #373737de;
    padding: 10px 20px;
    border-radius: 20px;
}

.countRandomBoxTitle {
    font-size: 12px;
    color: #FFF;
}

.countRandomBoxAmount {
    font-size: 18px;
    color: #FFF;
}

.content {
    padding-top: 5px;
    padding-bottom: 10px;
    color: #484848;
    position: relative;
    z-index: 5;
    background: #FFF;
    box-shadow: 0 -25px 20px -16px rgba(0, 0, 0, 0.04);
    border-radius: 50px;
    margin-top: 10px;
    border: 1px solid #eaeaea;
    border-bottom: none;
    height: 100%;
    max-width: 540px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tree02 {
    position: absolute;
    width: 250px;
    right: -80px;
    top: -160px;

}

.tree01 {
    position: absolute;
    width: 180px;
    right: -60px;
    top: -60px;
}

:global #but-content {
    --ion-background-color: rgba(255, 255, 255, 0)
}

.contentInput {
    width: 100vw;
    max-width: 540px;
    position: relative;
    z-index: 10;
    transition: all 0.5s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentInputActive {
    flex: 1;
}

.treeBackground {
    position: absolute;
    z-index: 2;
    top: -60px;
    left: -15px;
}

.treeBackground img:nth-child(1) {
    width: 65px;
}

.treeBackground img:nth-child(2) {
    width: 50px;
    margin-left: -15px;
}

.treeBackground img:nth-child(3) {
    width: 40px;
    margin-left: -5px;
}

.topic {
    font-size: 20px;
    padding: 10px 0;
}

.detail {
    padding-top: 10px;
}

.amountContent {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    background: #F4F4F4;
    border-radius: 10px;
}

.amount {
    width: 60px;
    padding: 5px 0;
    background: #FFF;
    border: 2px solid #8E8E8E;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    font-size: 28px;
    margin: 0 10px;
}

.remove {
    padding: 5px 10px;
    font-size: 45px;
    color: #D77979
}

.closeBtn {
    position: absolute;
    font-size: 40px;
    left: 20px;
    top: 5px;
    color: #A6A6A6
}

.add {
    padding: 5px 10px;
    font-size: 45px;
    color: #5AB04C
}

.allPrice {
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #F18938;
}

.unit {
    font-size: 12px;
    color: #5C5C5C;
    padding: 0 5px;
}


:global #have-a-tree-type {
    border: 1px solid #B6B6B6;
    padding: 2px 15px;
    border-radius: 10px;
    margin: 5px 0;
}

.addAddress {
    font-size: 12px;
    line-height: 15px;
    color: #285FB3;
    margin: 10px
}

.breakPoint {
    width: 250px;
    height: 1px;
    background-color: #C4C4C4;
    margin: 10px 0;

}

:global #receive-nft {
    margin-right: 10px;
    --size: 20px;
    --background-checked: yellowgreen;
}

:global #receive-nft::part(container) {
    border-radius: 6px;
    border: 2px solid #8E8E8E;
}

:global #receive-nft-content {
    --inner-border-width: 0;
    color: #4A4A4A;
}

.break {
    width: calc(100% - 40px);
    height: 1px;
    background-color: #C4C4C4;
    margin: 0 auto;
}

.contentOrder {
    align-self: stretch;
    padding: 10px 20px;
}

.topicOrder {
    font-size: 18px;
    margin-bottom: 5px;
}

.buttonSubmit {
    background: linear-gradient(180deg, #EF7732 0%, #F29C3E 100%);
    border-radius: 30px;
    color: #FFF;
    padding: 10px 50px;
    font-size: 18px;
    margin: 20px 0;
    transition: all 0.3s;
    position: relative;
    z-index: 10;
}

.buttonSubmit:active {
    opacity: 0.5
}

.buttonSubmit:disabled {
    opacity: 0.5
}

.errorMessage {
    color: red;
}
