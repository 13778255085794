
.container {
}

:global #modal-detail-tree {
    --background: rgba(255, 0, 0, 0) !important;
    --box-shadow: none;
}

.modalContent {
    background: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 30px;
    overflow: auto;
}

.tree01 {
    margin-bottom: 20px;
    width: 90px;
}

.tree02 {
    width: 90px;
    margin-bottom: 20px;
    margin-left: -40px;
}

.header {
    background: linear-gradient(180deg, #EE7631 0%, #F2A13F 100%);
    color: #FFF;
    text-align: center;
    padding: 15px 0;
    font-size: 20px;
}

.content {
    padding: 15px 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date {
    font-size: 14px;
    line-height: 15px;
    color: #616161;
}

.topic {
    color: #000000;
    font-size: 20px;
    padding: 10px 0;
    padding-bottom: 5px;
}

.amount {
    padding: 3px 15px;
    background: #AAC901;
    border-radius: 15px;
    color: #FFF;
}

.imageContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-self: center;
    margin-top: -50px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #FAFAFA;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.08);
}

.detail {
    color: #828282;
    font-size: 14px;
}

.highlight {
    color: #F07A33;
}

.formContent {
    padding: 0 10px;
}

.buttonContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}


.buttonContent button {
    border-radius: 30px;
    font-size: 18px;
    color: #000;
    background: #EDEDED;
    width: 200px;
    padding: 15px 0;
    margin-top: 10px;
    transition: all 0.3s
}

.buttonContent button:active {
    opacity: 0.5;
}

button.confirmButton {
    background: linear-gradient(180deg, #EE7631 0%, #F2A13F 100%);
    color: #FFFFFF;
}
