.container {
}

:global #modal-detail-tree {
    --background: rgba(255, 0, 0, 0) !important;
    --box-shadow: none;
}

.modalContent {
    background: #fff;
    border-radius: 40px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.tree01 {
    margin-bottom: 25px;
    width: 90px;
}

.tree02 {
    width: 90px;
    margin-bottom: 20px;
    margin-left: -40px;
}

.content {
    padding: 15px 0;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 500px;
    overflow: scroll;
}

.detailNft {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 15px 10px 20px 10px;
    margin: 0 20px;
    border-bottom: 1px solid #c5c5c5
}

.qrcodeContent {
    display: flex;
    align-items: center;
}

.qrcodeImageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
}

.receivedGift{
    font-size: 16px;
    line-height: 19px;
    color: #1E7B27;
    background: rgba(245, 245, 245, 0.96);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 5px 10px;
    position: absolute;
    top: 60px;
}

.qrcodeTopicContent {
    display: flex;
    align-items: center;
}

.qrcodePlantext {
    color: #545454;
    font-size: 12px;
}

.qrcodeEventText {
    color: #939393;
    font-size: 12px;
    line-height: 15px;
    padding: 5px 0;
}

.qrcodeTextContent {
    font-size: 14px;
    color: #F17B34;
    line-height: 17px;
}

.qrcodeTopic {
    font-size: 18px;
}

.nftImage {
    align-self: stretch;
    line-height: 19px;
}

.nftImage > div {
    opacity: 1;
    height: 250px !important;
}

.date {
    font-size: 14px;
    line-height: 15px;
    color: #616161;
}

.topic {
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    color: #F17B34;
}

.amount {
    padding: 3px 15px;
    background: #AAC901;
    border-radius: 15px;
    color: #FFF;
}

.headerContent {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-self: center;
    background: linear-gradient(180deg, #EE7631 0%, #F2A13F 100%);
    border-radius: 30px;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.08);
    padding: 0 20px;
    margin-top: -30px;
}

.headerTitle {
    font-size: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.58);
    align-self: stretch;
    text-align: center;
    padding: 0 80px;
}

.nftNumberId {
    font-size: 12px;
    color: #424242;
    padding: 5px 0;
    max-width: 300px;
}

.detail {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #333333;
    padding: 10px 0 30px 0;
}

.subDetail {
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #666666;
}

.highlight {
    color: #F07A33;
}

.buttonSubmit {
    background: linear-gradient(180deg, #EF7732 0%, #F29C3E 100%);
    border-radius: 30px;
    color: #FFF;
    padding: 10px 30px;
    font-size: 18px;
    margin-top: 20px;
    transition: all 0.3s
}
