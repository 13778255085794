.container {
    position: relative;
    background: #FFF;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    margin: 10px 15px;
    margin-top: 15px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    padding-left: 5px;
    align-items: center;
    transition: all 0.3s;
}

.container:active {
    opacity: 0.5
}

.contentImage {
    position: relative;
    margin-right: 10px;
    margin-top: -30px;
}

.tree01 {
    height: 90px;
    width: 70px;
}

.tree02 {
    height: 70px;
    width: 55px;
    margin-left: -30px
}

.content h3 {
    position: relative;
    z-index: 10;
    color: #ED853B;
    margin: 0;
    font-size: 24px;
}

.topic {
    margin-left:15px;
    color: #000000;
    font-size: 16px;
    padding: 5px 0;
}

.detail {
    color: #828282;
    font-size: 12px;
    margin-left:15px;
}

.highlight{
    color: #F07A33;
}

.date{
    font-size: 12px;
    line-height: 15px;
    color: #616161;
}

.amount{
    padding: 3px 15px;
    background: #AAC901;
    border-radius: 15px;
    color: #FFF;
}
