.container {
}

:global #modal-detail-tree {
    --background: rgba(255, 0, 0, 0) !important;
    --box-shadow: none;
}

.modalContent {
    background: #fff;
    border-radius: 40px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.tree01 {
    margin-bottom: 20px;
    width: 90px;
}

.tree02 {
    width: 90px;
    margin-bottom: 20px;
    margin-left: -40px;
}

.content {
    padding: 15px 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date {
    font-size: 14px;
    line-height: 15px;
    color: #616161;
}

.topic {
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    color: #F17B34;
}

.amount {
    padding: 3px 15px;
    background: #AAC901;
    border-radius: 15px;
    color: #FFF;
}

.imageContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-self: center;
    margin-top: -50px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #FAFAFA;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.08);
}

.detail {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #333333;
    padding: 10px 0 30px 0;
}

.subDetail {
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #666666;
}

.highlight {
    color: #F07A33;
}

.buttonSubmit {
    background: linear-gradient(180deg, #EF7732 0%, #F29C3E 100%);
    border-radius: 30px;
    color: #FFF;
    padding: 10px 30px;
    font-size: 18px;
    margin-top: 20px;
    transition: all 0.3s
}
