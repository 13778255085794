.imageContent {
    margin: 15px;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #e8e8e8;
    background-color: #FFF;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
}

.imageContent :global .splide__pagination {
    display: flex !important;
}

.imageContent :global .splide__pagination__page {
    background: #bebebe !important;
}

.imageContent :global .splide__pagination__page.is-active {
    background: #5b5b5b !important;
}
