
.pin-num-pad {
    width: 65px;
    height: 65px;
    margin: auto;
    --background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    --border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 36px;
}

.pin-num-pad-blank {
    width: 65px;
    height: 65px;
    margin: auto;
    --background: #ffffff;
    color: #ffffff;
    --border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    font-size: 36px;
}

.pin-row {
    margin: 32px 0
}

.pin-text{
    font-size: 3em;
    height: 50px;
}

.pin-text input[type="password"] {
    padding-left: 10px;
}

@media screen and (max-height: 700px) {
    .pin-row {
        margin: 15px 0
    }

    .pin-num-pad {
        width: 60px;
        height: 60px;
        margin: auto;
        --background: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        --border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
        font-size: 26px;
        font-family: 'Lato', sans-serif;
    }

    .pin-num-pad-blank {
        width: 60px;
        height: 60px;
        margin: auto;
        --background: #ffffff;
        color: #ffffff;
        --border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        cursor: pointer;
        font-size: 26px;
    }
}
