.container {
    position: relative;
    height: 300px;
    overflow: hidden;
    opacity: 0.5;
    transition: all 0.3s
}

:global .splide__slide.is-active > div{
    opacity: 1;
}

:global .splide__track--nav>.splide__list>.splide__slide.is-active{
    border: none;
}

.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 5
}

.imagePreviewBackdrop {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(20px);
    -webkit-filter: blur(20px);
    transform: scale(1.3);
    opacity: 0.8;
}
