.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    background-color: #FFF;
    z-index: 5
}

.contentImage {
    position: relative;
}

.circle01 {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    left: -20px;
    bottom: -25px;
    background: #ECECEC;
}

.circle02 {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    left: 67px;
    bottom: -16px;
    background: #E4E4E4;
}

.circle03 {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    left: 55px;
    bottom: 30px;
    background: #D9D9D9;
}

.tree01 {
    width: 100px;
    position: relative;
    z-index: 5
}

.tree02 {
    width: 80px;
    margin-left: -45px;
    position: relative;
    z-index: 5
}

.content {
    padding-top: 20px;
    text-align: center;
}


.content h3 {
    position: relative;
    z-index: 10;
    color: #ED853B;
    margin: 0;
    font-size: 24px;
}

.contentDetail {
    color: #606060;
    line-height: 25px;
    padding-top: 5px;
}

.contentSubDetail {
    font-size: 12px;
    color: #606060;
    padding-bottom: 10px;
}
