.container {
}


:global #modal-detail-tree {
    --background: rgba(255, 0, 0, 0) !important;
    --box-shadow: none;
    --height: 100%;
}

.modalContent {
    background: #fff;
    border-radius: 40px 40px 0 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.modalContentScroll {
    max-height: 450px;
    overflow: scroll;
}

.timelineTitle {
    color: #444444;
    padding: 20px 20px;
    font-size: 18px;
}

.timelineContent {
    padding-right: 40px !important;
}

.timelineDetail {
    font-size: 14px !important;
    margin: 0 !important;
}

.tree01 {
    margin-bottom: 20px;
    width: 90px;
}

.tree02 {
    width: 90px;
    margin-bottom: 20px;
    margin-left: -40px;
}

.content {
    padding: 15px 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date {
    font-size: 14px;
    line-height: 15px;
    color: #616161;
}

.topic {
    color: #000000;
    font-size: 20px;
    padding: 10px 0;
    padding-bottom: 5px;
}

.amount {
    padding: 3px 15px;
    background: #AAC901;
    border-radius: 15px;
    color: #FFF;
}

.imageContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-self: center;
    margin-top: -50px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #FAFAFA;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.08);
}

.detail {
    color: #828282;
    font-size: 14px;
}

.highlight {
    color: #F07A33;
}

.emptyThumbnail {
    background-color: #e1e1e1;
    color: #3b3b3b;
    margin: 10px 20px;
    padding: 60px 0;
    text-align: center;
    border-radius: 20px;
}
