.container {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
    background: #F5F5F5;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    transition: all 0.3s
}

.container:active {
    opacity: 0.5
}

.container.active {
    opacity: 1
}

.orderNumber {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #676767;
}

.content {
    display: flex;
    align-items: center;
}

.amountBoxContent {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.boxImage {
    width: 52px;
    height: 52px;
    position: relative;
    z-index: 2;
}

.amountBox {
    position: relative;
    background: #FFF;
    padding: 3px 20px;
    padding-left: 30px;
    margin-left: -20px;
    text-align: center;
    border: 1px solid #DDDDDD;
    border-radius: 14px;
}

.amount {
    font-size: 20px;
    line-height: 24px;
}

.amountUnit {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #A9A9A9;
}

.statusContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.statusContent {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 0 20px;
}

.detail {
    font-size: 10px;
}

.nextBtn {
    font-size: 32px;
    color: #9F9F9F;
}

.nextBtnHidden {
    visibility: hidden;
}

.success {
    color: #5AB04C
}

.error {
    color: red
}
